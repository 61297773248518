import { localStorage } from '@catalogo/core-local-storage';
import { replaceUrlParams } from '@catalogo/service-americanas-product/src/helpers/url';
import { IAdsModalEffectProps } from './types';

export const updateStorage = (campaignName: string, expiresDays?: number) => {
  if (campaignName && expiresDays) {
    const now = new Date();
    const value = {
      expires: now.getTime() + 60 * 60 * 24 * expiresDays * 1000,
      createdAt: now.toLocaleDateString('pt-BR'),
    };

    localStorage.setItem(campaignName, JSON.stringify(value));
  }
};

export const adsModalEffect = ({ history, publication }: IAdsModalEffectProps) => () => {
  const { children, closeButtonColor, imageWidth, campaignName, expiresDays } = publication ?? {};

  const modalHash = '#modal-splashscreen';
  const hash = history?.location?.hash;
  const modalIsOpen = hash === modalHash;

  if (publication && !modalIsOpen) {
    const campaignData = localStorage.getItem(campaignName);
    const now = new Date();

    if (!campaignData || now.getTime() > campaignData?.expires) {
      replaceUrlParams(history, modalHash, false, {
        publication: children?.[0],
        closeButtonColor,
        imageWidth,
        disableMetric: true,
      })();
      updateStorage(campaignName, expiresDays);
    }
  }
};
