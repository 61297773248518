import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { adsModalEffect } from './helpers';
import { IProps } from './types';

const AdsModal: React.FC<IProps> = props => {
  const history = useHistory();
  const { publication } = props;

  useEffect(adsModalEffect({ history, publication }), [publication?.publicationId]);

  return <></>;
};

export default AdsModal;
