import { History } from 'history';
import { Config } from '@catalogo/core-configuration';

export const replaceUrlParams = (
  history: History,
  params: string,
  replaceAll = true,
  state: Record<string, unknown> | null = null
) => () => {
  let urlParams = params;
  if (!replaceAll) {
    const currentParams = history?.location?.search ?? '';
    urlParams = `${currentParams}${params}`;
  }

  if (state) {
    history.replace(urlParams, state);
  } else {
    history.replace(urlParams);
  }
};
export const redirectFromLogin = (config: Config) => () => {
  const currentHref = globalThis.location.href;
  const urlLogin = config?.baseLoginUrl;

  globalThis.location.href = `${urlLogin}?next=${currentHref}`;
};
